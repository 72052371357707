// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/About.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../src/pages/Contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-home-jsx": () => import("./../src/pages/Home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-jsx": () => import("./../src/pages/Live.jsx" /* webpackChunkName: "component---src-pages-live-jsx" */),
  "component---src-pages-services-jsx": () => import("./../src/pages/Services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

